<template>
  <div v-if="!detailVisible">
    <div class="card flex">
      <el-form :model="form" :inline="true">
        <el-form-item label="所属门店">
          <el-select v-model="form.hierarchy_id" placeholder="请选择">
            <template v-for="item in merchantList">
              <el-option :label="item.hierarchy_name" :value="item.hierarchy_id" :key="item.hierarchy_id"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="材质成色">
            <el-select
              v-model="form.attr_value_id"
              placeholder="请选择"
              filterable
              clearable
            >
              <template v-for="item in materialList">
                <el-option
                  :label="item.attr_value"
                  :value="item.attr_value_id"
                  :key="item.attr_value_id"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click="onSubmit">搜索</el-button>
        <el-button  @click="resetForm">重置</el-button>
      </div>
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        :row-class-name="picRowClassName"
        @row-dblclick="handleDalclick"
        height="700"
        stripe
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width">
            <template slot-scope="scope">
              {{(scope.row[item.field_alias] ||  scope.row[item.field_alias] === 0) ? scope.row[item.field_alias] : '--'}}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text"  @click="toDetal(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="$store.state.pageSizes[0]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
  </div>
  <Detail v-else @onBack="detailVisible = false" :row="currRow" />
</template>

<script>
import Detail from './Detail.vue';
import { getMerchantListReq } from '@/api/authority/config';
import {
  getMaterialListReq,
} from "@/api/goods/raw/in/index";
import {
  getListReq,
} from "@/api/goods/raw/goldList/index";

export default {
  data() {
    return {
      currRow: {},
      materialList: [],
      merchantList: [],
      loading: false,
      detailVisible: false,
      form: {},
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '所属门店', field_alias: 'hierarchy_name' },
        { field_text: '材质成色', field_alias: 'attr_value' },
        { field_text: '净金重', field_alias: 'gold_weight' },
        // { field_text: '金额', field_alias: 'amount_money' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
      page: 1,
      limit: this.$store.state.pageSizes[0],
    };
  },
  components: {
    Detail,
  },
  created() {
    this.getMerchantList();
    this.getMaterialList();
    this.getList();
  },
  methods: {
    toDetal(row) {
      this.currRow = row;
      this.detailVisible = true;
    },
    handleDalclick(row) {
      this.currRow = row;
      this.detailVisible = true;
    },
    picRowClassName({ row, rowIndex }) {
      // 给每一行添加索引
      row.index = rowIndex + 1;
    },
    getList() {
      this.loading = true;
      getListReq({
        ...this.form,
        page: this.page,
        limit: this.limit,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取材质成色列表
    getMaterialList() {
      getMaterialListReq().then((res) => {
        if (res.code === 1) {
          this.materialList = res.data;
        }
      });
    },
    getMerchantList() {
      getMerchantListReq()
        .then((res) => {
          if (res.code === 1) {
            this.merchantList = res.data;
          }
        });
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
    onSubmit() {
      this.getList();
    },
    resetForm() {
      this.form = {};
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item {
  margin-bottom: 0px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
</style>
