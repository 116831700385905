import { post } from '@/utils/request';

const getListReq = (params) => post({
  url: '/store/material.MaterialDataCount/getList',
  data: {
    ...params,
  },
});

// 详情
const getDetailReq = (params) => post({
  url: '/store/material.MaterialDataCount/getCountDataDetails',
  data: {
    ...params,
  },
});

export {
  getDetailReq,
  getListReq,
};
