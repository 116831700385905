<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="金料清单详情" >
      </el-page-header>
    </div>
    <div class="card top">
      <div>
        所属门店：{{tableData.hierarchy_name}}
      </div>
      <div>
        材质成色：{{tableData.attr_value}}
      </div>
      <div style="font-weight: bold; font-size: 15px;">
        当前结余
      </div>
      <div style="margin-left: 5px;">
        净金重：{{tableData.total_gold_weight}}
      </div>
      <!-- <div>
        总金额：{{tableData.total_amount_money}}
      </div> -->
    </div>
    <div class="table">
      <el-table
        :data="tableData.list"
        style="width: 100%"
        v-loading="loading"
        height="680"
        stripe
        :row-class-name="picRowClassName"
      >
        <template v-for="item in rowList">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            align="center"
            :width="item.t_width"
          >
            <template slot-scope="scope">
              {{
                scope.row[item.field_alias] ? scope.row[item.field_alias] : "--"
              }}
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="pagina">
      <el-pagination
        background
        :current-page="page"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="$store.state.pageSizes"
        :page-size="$store.state.pageSizes[0]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getDetailReq } from '@/api/goods/raw/goldList';

export default {
  data() {
    return {
      loading: false,
      rowList: [
        { field_text: '序号', field_alias: 'index' },
        { field_text: '净金重', field_alias: 'gold_weight' },
        { field_text: '单价', field_alias: 'unit_price' },
        // { field_text: '总金额', field_alias: 'amount_money' },
        { field_text: '创建时间', field_alias: 'create_time' },
        { field_text: '摘要', field_alias: 'material_entry_type' },
      ],
      tableData: {
        list: [],
        total: 0,
      },
      page: 1,
      limit: this.$store.state.pageSizes[0],
    };
  },
  props: {
    row: Object,
  },
  created() {
    this.getDetail();
  },
  methods: {
    picRowClassName({ row, rowIndex }) {
      // 给每一行添加索引
      row.index = rowIndex + 1;
    },
    getDetail() {
      this.loading = true;
      getDetailReq({
        attr_value_id: this.row.attr_value_id,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    goBack() {
      this.$emit('onBack');
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  display: flex;
  margin-top: 10px;
  div {
    margin-left: 40px;
  }
}
</style>
